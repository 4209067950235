<template>
	<div class="ui-common">
		<div class="ui-common__item" v-for="(item, index) in list" :key="index" v-permission="[item.permission]" @click="onGoto(item)">
			<img class="ui-common__icon" :src="item.icon" />
			<p class="ui-common__text">{{ item.text }}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default:() => []
			}
		},
		methods: {
			onGoto(item) {
				this.$router.push({
					path: item.link
				})
			}
		}
	}
</script>

<style scoped>
	.ui-common {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.ui-common__item {
		width: 130px;
		height: 128px;
		padding: 20px 0;
		margin-right: 20px;
		margin-top: 20px;
		text-align: center;
		background: rgba(79, 126, 245, 0.1);
		border-radius: 5px 5px 5px 5px;
	}
	
	.ui-common__icon {
		width: 68px;
		height: 73px;
		margin: auto;
	}
	.ui-common__text {
		padding: 0;
		margin: 8px 0 0;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		color: #303A5D;
	}
	/* @media screen and (min-width: 1801px) { */
	@media screen and (min-width: 1984px) and (max-width: 2240px){
		.ui-common__item:nth-child(5n) {
			margin-right: 0;
		}
	}
	
	/* @media screen and (min-width: 1585px) and (max-width: 1800px) { */
	@media screen and (min-width: 1599px) and (max-width: 1800px) {
		.ui-common__item:nth-child(4n) {
			margin-right: 0;
		}
	}
	/* @media screen and (min-width: 1401px) and (max-width: 1584px) { */
	@media screen and (min-width: 1420px) and (max-width: 1584px) {
		.ui-common__item:nth-child(3n) {
			/* margin-right: 300; */
		}
	}
	/* @media screen and (min-width: 1274px) and (max-width: 1400px) { */
	@media screen and (min-width: 1281px) and (max-width: 1400px) {
		.ui-common__item:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media screen and (min-width: 1200px) and (max-width: 1273px) {
		.ui-common__item:nth-child(2n) {
			margin-right: 0;
		}
	}
</style>