<template>
	<div>
		<div class="ui-inventory__row">
			<div class="ui-inventory__item" @click="onGotoPage('/inventory/task')">
				<div class="ui-inventory__num">{{inventoryData?.num}}</div>
				<div class="ui-inventory__text">应盘资产(件)</div>
			</div>
			<div class="ui-inventory__item" @click="onGotoPage('/inventory/task')">
				<div class="ui-inventory__num">{{inventoryData?.alreadyNum}}</div>
				<div class="ui-inventory__text">已盘资产(件)</div>
			</div>
			<div class="ui-inventory__item" @click="onGotoPage('/inventory/task')">
				<div class="ui-inventory__num">{{inventoryData?.lossNum}}</div>
				<div class="ui-inventory__text">盘亏资产(件)</div>
			</div>
			<div class="ui-inventory__item" @click="onGotoPage('/inventory/task')">
				<div class="ui-inventory__num">{{inventoryData?.profitNum}}</div>
				<div class="ui-inventory__text">盘盈资产(件)</div>
			</div>
		</div>
		<div>
			<div id="chart"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	let eChartsInstall;
	export default {
		props: ['inventoryData','unitRank'],
		data() {
			dataList: []
		},
		mounted() {
			this.onChartDraw()
		},
		computed: {
			uniNameData() { // 单位名
				let tempArr = []
				this.unitRank.forEach(item => { 
					tempArr.push(item.dept)
					// if(item.departmentName !== null) {
					// 	tempArr.push(item.departmentName)
					// }else {
					// 	tempArr.push('')
					// }
				})
				return tempArr
			},
			lossAssetsData() { // 盘亏
				let tempArr = []
				this.unitRank.forEach(item => {
					tempArr.push(item.loss || 0)
				})
				return tempArr
			},
			alreadyCheckData() { // 应盘
				let tempArr = []
				this.unitRank.forEach(item => {
					tempArr.push(item.already)
				})
				return tempArr
			}
		},
		watch: {
			unitRank: {
				handler(newval,oldval) {
						// this.unitRank1 = newval // data中定义的变量
					setTimeout(() => {
						this.$nextTick(() => {
							this.onChartDraw();
						})
					})
				},
				deep: true,
				// immediate:true
			}
		},
		methods: {
			onGotoPage(link) {
				this.$router.push({
					path: link
				})
			},
			onChartDraw() {
				// let xData = ['教研股', '人事股', '教学股', '师顺股', '后勤办', '政教股', '办公室', '计财股', '校检股', '体育办'];
				let xData = this.uniNameData
				var chartDom = document.getElementById('chart');
				echarts.init(chartDom).dispose();
				eChartsInstall = echarts.init(chartDom);
				let options = {
					grid: {
						left: '3%',
						right: '4%',
						top: '8%',
						bottom: xData.length > 10 ? '20%' : '14%',
						containLabel: true
					},
					legend: {
						bottom: 0,
					},
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
					      // Use axis to trigger tooltip
					      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					    }
					},
					xAxis: {
						type: 'category',
						// boundaryGap: false,
						data: xData,
						axisLabel: {
							interval:0,
							formatter:function(value){ // 类目轴标题换行
								var str = "";
								var num = 9;//每行显示字数
								var valLength = value.length;//该项x轴字数
								var rowNum = Math.ceil(valLength/num);//行数
								if(rowNum >1) {
									for(var i=0;i<rowNum;i++){
										var temp= "";
										var start = i * num;
										var end = start + num;
										temp =value.substring(start,end) + "\n";
										str += temp;
									}
									return str;
								} else {
									return value;
								}
							}
						}
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '盘亏资产',
							type: 'bar',
							barWidth: 10,
							color: '#4982F2',
							stack: 'Total',
							emphasis: {
								focus: 'series'
							},
							// data: [320, 100, 200, 150, 250, 140, 190, 280, 210, 170]
							data: this.lossAssetsData
						},
						{
							name: '已盘资产',
							type: 'bar',
							color: '#75EFE6',
							barWidth: 10,
							stack: 'Total',
							// data: [10, 20, 15, 20, 5, 8, 16, 22, 9, 10]
							data: this.alreadyCheckData
						}
					]
				};
				if (xData.length > 10) {
					options.dataZoom = [{
							type: 'inside',
							minValueSpan: 7,
							maxValueSpan: 10,
							zoomLock: true,
							zoomOnMouseWheel: false
						},
						{
							height: 20,
							minValueSpan: 7,
							maxValueSpan: 10,
							zoomLock: true,
							zoomOnMouseWheel: false
						}
					]
				}
				this.$nextTick(() => {
					eChartsInstall.setOption(options);
					this.resize();
				})
			},
			resize() {
				if (this.isListener) return;
				window.addEventListener('resize', (e) => {
					if (this.timeOut) {
						clearTimeout(this.timeOut);
					}
					this.timeOut = setTimeout(() => {
						this.timeOut = null;
						eChartsInstall.resize();
					}, 300);
				}, true);
				this.isListener = true;
			},
		}
	}
</script>

<style scoped>
	.ui-inventory__row {
		display: flex;
		margin-top: 20px;
		justify-content: space-between;
		/* flex-wrap: wrap; */
	}
	.ui-inventory__item {
		/* width: 168px; */
		width: 23.5%;
		height: 98px;
		padding: 20px 0;
		margin-bottom: 10px;
		background: #EDF2FE;
		border-radius: 5px;
		text-align:center;
		cursor: pointer;
	}
	.ui-inventory__num {
		font-size: 24px;
		font-weight: bold;
		color: #4982F2;
		line-height: 28px;
	}
	.ui-inventory__text {
		margin-top: 10px;
		font-size: 14px;
		font-weight: 400;
		color: #303A5D;
		line-height: 16px;
	}
	#chart {
		width: 100%;
		height: 300px;
	}
</style>