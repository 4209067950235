import { send } from "../index";
// 获取文献列表
export function getUnitInventoryDetail(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/unitInventoryDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 右侧数据统计
export function getFiscalAnalysis(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/fiscalAnalysis.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 获取当前任务
export function getCurrentTask(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/currentTask.do",
    method: "POST",
    data,
    ...opts,
  });
}