<template>
	<div>
		<ul class="ui-notice__box">
			<li class="ui-notice__item" v-for="(item, index) in list" :key="item" @click="onDetail(item)">
				<div class="ui-notice__top">
					<!-- <div class="ui-notice__title" v-html="item.title">关于开展2023年度国有资产盘点工作的通知</div> -->
					<div class="ui-notice__title">{{item.title}}</div>
					<Icon icon="RightOutlined" style="font-size: 10px;color: #666666;"></Icon>
				</div>
				<div v-html="item.content" class="ui-notice__info" v-if="index === 0">
				</div>
			</li>
		</ul>
		<detailModal ref="detailModalRef"></detailModal>
	</div>
</template>

<script>
	import { getPlatText } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import detailModal from '@/views/system/notice/components/detailModal.vue';
	export default {
		components: { Icon, detailModal },
		data() {
			return {
				list: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async getData() {
				try {
					let ret = await getPlatText({
						page: 1,
						pageSize: 4,
						type: 2
					});
					if (ret.code === 200) {
						this.list = ret.data.list;
					}
				} catch(e) {
				}
			},
			onDetail(item) {
				this.$refs.detailModalRef.open(item);
			}
		}
	}
</script>

<style scoped>
	.ui-notice__box {
		margin: 0;
		padding-left: 20px;
	}
	.ui-notice__item {
		padding: 20px 0;
		cursor: pointer;
	}
	.ui-notice__item + .ui-notice__item {
		border-top: solid 1px #E7E7E7;
	}
	.ui-notice__top {
		display: flex;
		justify-content: space-between;
	}
	.ui-notice__title {
		flex: 1;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ui-notice__info {
		margin-top: 15px;
		padding-right: 10px;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 16px;
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow:hidden;
	}
</style>