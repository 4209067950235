<template>
	<div>
		<p class="ui-task">当前盘点任务：{{currTaskInfo.name}}</p>
		<a-table rowKey="id" class="table" :dataSource="changeInventory" size="small" :pagination="pagination" :columns="columns" bordered :scroll="{ x: 600 }">
			<template #bodyCell="{ column, record, index }">
				<!-- <template v-if="column.key === 'action'">
					<a-button type="link" size="small" @click="onResult(record.id)">盘点结果</a-button>
				</template> -->
				<!-- <template v-if="column.key === 'index'">
					{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
				</template> -->
				<template v-if="column.key === 'status'">
					<div v-if="record.status === 1">未开始</div>
					<div v-if="record.status === 2">进行中</div>
					<div v-if="record.status === 3">待确认</div>
					<div v-if="record.status === 4">已完成</div>
				</template>
			</template>
		</a-table>
		<!-- <resultModal ref="resultModalRef" @onReset="onReset"></resultModal> -->
	</div>
</template>

<script>
	import { getUnitTaskSearchList } from '@/service/modules/inventory.js';
	import {  getCurrentTask } from '@/service/modules/statistics.js';
	export default {
		props: ['inventoryData',"currTaskInfo"],
		// components: { resultModal },
		data() {
			return {
				loading: false,
				changeInventory: [],
				// inventoryData: [],
				taskName: '',
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [
				// 	{
				// 	title: '行号',
				// 	align: 'center',
				// 	key: 'index'
				// }, 
				{
					title: '单位名称',
					align: 'center',
					dataIndex: "unitName",
					width: 190
				}, { 
					title: '应盘资产（件）',
					align: 'center',
					dataIndex: "num"
				}, {
					title: '已盘资产（件）',
					align: 'center',
					dataIndex: "alreadyNum"
				}, {
					title: '盘亏资产（件）',
					align: 'center',
					dataIndex: "lossNum"
				}, {
					title: '盘盈资产（件）',
					align: 'center',
					dataIndex: "profitNum"
				}, {
					title: '状态',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				},  
				// {
				// 	title: '操作',
				// 	align: 'center',
				// 	key: 'action'
				// },
				],
			}
		},
		watch: {
			inventoryData: {
				handler(newval,oldval) {
					// console.log("222",newval)
						// this.unitRank1 = newval // data中定义的变量
					this.$nextTick(() => {
						this.changeInventory = newval || []
					})
				},
				deep: true,
				immediate:true
			}
		},
		created() {
			// this.getCurrentTaskFun()
		},
		// computed: {
		// 	changeInventory() {
		// 		console.log("inventoryData2",this.inventoryData)
		// 		return this.inventoryData
		// 	}
		// },
		motheds: {
			// onResult() {},
			async getCurrentTaskFun() {
				try {
					let ret = await getCurrentTask({});
					if (ret.code === 200) {
						// this.currTaskId = ret.data.id;
						// this.getUnitTaskSearchListFun(ret.data.id)
					}
				} catch(e) {
					
				}
			},
			async getUnitTaskSearchListFun(id) {
				try {
					let ret = await getUnitTaskSearchList({
						taskId: id
					});
					if (ret.code === 200) {
						this.inventoryData = ret.data.list;
					}
				} catch(e) {
					
				}
			},
			onResult(item) {
				this.$refs.resultModalRef.open(item);
			}
		}
	}
</script>

<style scoped>
	.ui-task {
		margin-top: 30px;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 21px;
	}
</style>