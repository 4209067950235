import { send } from "../index";
// 获取文献列表
export function getMyBacklogList(data, opts = {}) {
  return send({
    url: "/admin/backlog/myBacklogList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 获取我的资产
export function getMyAssets(data, opts = {}) {
  return send({
    url: "/admin/assets/myAssets.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 获取我的盘点
export function getMyInventory(data, opts = {}) {
  return send({
    url: "/admin/inventoryTask/myInventory.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 确认撤销下账或报废 财政
export function postConfirmRevokeApply(data, opts = {}) {
  return send({
    url: "/admin/backlog/confirmRevokeApply.do",
    method: "POST",
    data,
    ...opts,
  });
}