<template>
	<div style="margin-top: 10px;">
		<div class="ui-todo__item" v-for="item in list" :key="item.id" @click="onGotoPage">
			<h4 class="ui-todo__warn">
				<div class="ui-todo__icon"></div>
				【{{item.title}}】
			</h4>
			<div class="ui-todo__info">
				<div class="ui-todo__title">
					<!-- 2023年度国有资产盘点截止时间快到了，请及时提交盘点结果！ -->
					{{item.content}}
				</div>
				<div class="ui-todo__time">
					<!-- 2分钟前 -->
					{{transDateTime(item.createTime)}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getMyBacklogList } from '@/service/modules/jobs.js';
	export default {
		data() {
			return {
				list: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async getData() {
				try {
					let ret = await getMyBacklogList({
						page: 1,
						pageSize: 5,
					});
					if (ret.code === 200) {
						this.list = ret.data.list;
					}
				} catch(e) {
				}
			},
			onGotoPage() {
				this.$router.push({
					path: "/jobs/todoList"
				})
			}
		}
	}
</script>

<style scoped>
	.ui-todo__item {
		padding: 20px 0;
		cursor: pointer;
	}
	.ui-todo__item + .ui-todo__item {
		border-top: solid 1px #E7E7E7;
	}
	.ui-todo__warn {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		line-height: 19px;
	}
	.ui-todo__icon {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #FF9F2E;
	}
	.ui-todo__info {
		display: flex;
		margin-top: 14px;
		align-items: center;
		justify-content: space-between;
	}
	.ui-todo__title {
		font-size: 18px;
		font-weight: 400;
		color: #666666;
		line-height: 21px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
		flex: 1;
	}
	.ui-todo__time {
		width: 180px;
		font-size: 18px;
		font-weight: 400;
		color: #999999;
		line-height: 21px;
		text-align: right;
	}
</style>